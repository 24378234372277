import {Component, ViewChild,} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AsideMenuComponent} from './components/aside-menu/aside-menu.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {


  @ViewChild(AsideMenuComponent) asidemenu:any;



  appasidemenu:any;
  containerfooter:any;


  constructor() {
  }

  private navbarclass = new BehaviorSubject<string>('navbar-primary');

  public observablenavbarclass = this.navbarclass.asObservable();

  public changenavbarclass(msg:string):void {
    this.navbarclass.next(msg);
  }

  private navbarcss = new BehaviorSubject<string>('expand');

  public observablenavbarcss = this.navbarcss.asObservable();

  public changenavbarcss(msg:string):void {
    this.navbarcss.next(msg);
  }



  ngOnInit(): void {


    this.observablenavbarcss.subscribe(msg => this.containerfooter = 'container-footer-' + msg);
    this.observablenavbarcss.subscribe(msg => this.appasidemenu = 'appasidemenu-' + msg);

  }



}
