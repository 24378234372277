
<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
        <nav class="navbar-primary">


            <ul class= navbar-primary-menu>
                <li>
                    <a
                        routerLinkActive="active"
                        routerLink="/dashboard" class="a">
                        <img class="dashboard-icon" src="..\assets\img\Recurso 7.svg">
                        <span class="nav-label"> {{'aside.dashboard' | translate}}</span></a>
                </li>

                <li> <a routerLinkActive="active"
                        routerLink="/historical" class="a">
                        <img class="historical-icon" src="..\assets\img\Recurso 2.svg">
                        <span class="nav-label"> {{'aside.historical' | translate}}</span>
                        <span class="nav-label"> {{'aside.inform' | translate}}</span></a>
                </li>

                <!-- <li> <a routerLinkActive="active"
                        routerLink="/invoices" class="a">
                        <img class="invoices-icon" src="..\assets\img\Recurso 3.svg">

                        <span class="nav-label"> {{'aside.invoices' | translate}}</span></a>
                </li> -->

                <li> <a routerLinkActive="active"
                        routerLink="/settings" class="a">
                        <img class="settings-icon" src="..\assets\img\Recurso 4.svg">

                        <span class="nav-label"> {{'aside.configuration' | translate}}</span></a>
                </li>


            </ul>
        </nav>
