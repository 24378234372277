import { Component, Input, OnInit } from '@angular/core';

@Component({

  selector: 'app-linechart',
  templateUrl: './linechart.component.html',
  styleUrls: ['./linechart.component.scss']
})
export class LinechartComponent implements OnInit {

  constructor() { }

  @Input() chartUtils: any = [];
  @Input() chartLayout: any = [];
  @Input() chartStyle: any = [];
  @Input() chartWidth: any = [];

  ngOnInit(): void {



  }

  colorway = [
    '#33cbcf',
    '#005BB7',
    '#009bd8',
    '#82bf5a',
    '#000000',
    '#1a1f71'
  ];
  cultureInfo = 'ES';

  layout = {
    margin: {
      l: -20,
      r: 50,
      b: 50,
      t: 50
    },
    showlegend: true,
    barmode: "relative",
    legend: { x: 0, y: -0.2, orientation: "h" },
    colorway: this.colorway,

    xaxis: {
      type: 'date',
      automargin: true,
      autorange: true,
      showgrid: true,
      showline: true,
      zeroline: false,
    },
    yaxis: {
      automargin: true,
      autorange: true,
      showgrid: true,
      showline: true,
      zeroline: false,
      ticksuffix: " kW",
    },
    yaxis2: {
      overlaying: 'y',
      side: 'right',
      ticksuffix: '%'
    },
    modeBarButtonsToAdd: []
  };

  config= {
      responsive: true,
      scrollZoom: true,
      displayModeBar: true,
      toImageButtonOptions: {
        format: 'png', // one of png, svg, jpeg, webp
        filename: 'ChartResults',
        height: 300,
        width: 600,
        scale: 1
      },
      modeBarButtonsToRemove: ['lasso2d', 'select2d'],
      locale: this.cultureInfo,
      displaylogo: false,
      autosize: true,
      width: 1000,
    };
}

