import {Injectable} from '@angular/core';

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const EXPIRES = 'expires_in';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  getToken(): string|null {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN) ?? '';
  }

  saveToken(token:string): void {
    localStorage.setItem(ACCESS_TOKEN, token);
  }

  saveRefreshToken(refreshToken:string): void {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  saveExpires(expires:any): void {
    localStorage.setItem(EXPIRES, expires);
  }

  getExpires(): number|null {

    return Number(localStorage.getItem(EXPIRES));
  }

  removeToken(): void {
    localStorage.removeItem(ACCESS_TOKEN);
  }

  removeRefreshToken(): void {
    localStorage.removeItem(REFRESH_TOKEN);
  }

  removeExpires(): void{
    localStorage.removeItem(EXPIRES);
  }
}
