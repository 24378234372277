<link rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />

<header (window:resize)="onResize($event)">
  <nav class="header">
    <div class="user-config">
      <!-- <div class={{installationsView}}> -->
      <button routerLink="/installations" mat-icon-button [disableRipple]="true">
        <img class="settings-icon" src="..\assets\img\header\Icon - Instalaciones.svg">
        <span class="label">
          {{installationName}}
        </span>
      </button>
      <!--  </div> -->


      <button mat-icon-button [disableRipple]="true" [matMenuTriggerFor]="menu">
        <img class="settings-icon" src="..\assets\img\header\Icon - User.svg">

      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/user">
          <a routerLinkActive="active"><span>{{'header.profile' | translate}}</span></a>
        </button>
        <button mat-menu-item (click)="logout()">
          <span>{{'header.logout' | translate}}</span>
        </button>
      </mat-menu>


      <button mat-icon-button [disableRipple]="true" [matMenuTriggerFor]="menuLang">
        <span class="label">
          {{translate.currentLang}}
        </span>
      </button>
      <mat-menu #menuLang="matMenu">
        <button mat-menu-item *ngFor="let language of languages" (click)="setLanguage($event)">
          <a><span>{{ language }}</span></a>
        </button>
      </mat-menu>


    </div>
    <div class="button-image">
      <!--  <button class="button-menu" type="submit" (click)="hiddenmenu()"><span class="material-symbols-outlined">
              menu
              </span></button> -->

      <img class='login-image' src="../assets/img/Edinor_solo.svg" *ngIf="!loginImageCollapsed" />
      <img class='login-image-collapsed' src="../assets/img/e_solo.svg" *ngIf="loginImageCollapsed" />

    </div>
  </nav>
</header>
