import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {LayoutComponent} from '../../layout.component';
import {User} from "../../../core/models/user.interface";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  navbarclass: any = 'navbar-primary';
  navbarcss: any = 'expand';
  public user: User = JSON.parse(localStorage.getItem('user') || 'null');
  constructor(public translate: TranslateService, public router: Router, public layout: LayoutComponent) { }
  loginImageCollapsed :any;

  //installationsView: any = 'viewInstallations';

  languages = ['es', 'eu'];
  installationName= localStorage.getItem("installationName");

  ngOnInit(): void {

    this.layout.observablenavbarclass.subscribe(msg => this.navbarclass = msg);
    this.layout.observablenavbarcss.subscribe(msg => this.navbarcss = msg);
    this.loginImageCollapsed = window.innerWidth <= 725;
/*
    var installationsSize = localStorage.getItem('installationsSize');

    if(installationsSize == '0' || installationsSize == '1'){
      this.installationsView = 'noneView';

    } */
  }

  onResize(event:any) {
    if(event.target.innerWidth <= 725){
      if(this.navbarclass == 'navbar-primary'){
        this.layout.changenavbarclass('navbar-primary collapsed');
        this.layout.changenavbarcss('collapse');
      }
      this.loginImageCollapsed = true;
    }else{

      this.loginImageCollapsed = false;
    }
  }
  hiddenmenu(){


    if(this.navbarclass == 'navbar-primary'){
      this.layout.changenavbarclass('navbar-primary collapsed');
      this.layout.changenavbarcss('collapse');

    } else {
      this.layout.changenavbarclass('navbar-primary');
      this.layout.changenavbarcss('expand');

    }

  }

  public setLanguage = (ev: any) => {
    var language = ev.target.innerText;

    if (this.translate.currentLang === language) return;

    localStorage.setItem('language', language);
    this.translate.use(language);

  }
  public logout() {
    localStorage.clear();
    this.router.navigateByUrl('auth/login');
  }

}
