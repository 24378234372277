import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {LayoutComponent} from '../../layout.component';
import {HeaderComponent} from '../header/header.component';

@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss']
})
export class AsideMenuComponent implements OnInit {

  navbarclass: any = 'navbar-primary';
  navbarcss: any = 'expand';

  constructor(public header: HeaderComponent, public layout: LayoutComponent) { }

  ngOnInit(): void {

    this.layout.observablenavbarclass.subscribe(msg => this.navbarclass = msg);
    this.layout.observablenavbarcss.subscribe(msg =>  this.navbarcss = msg);

  }

  @Output() messageEvent = new EventEmitter<string>();


}
