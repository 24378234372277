import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser'
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from './layout';
import { GoogleChartsModule } from 'angular-google-charts';
import { PlotlyModule } from 'angular-plotly.js';
import * as PlotlyJS from 'plotly.js-dist-min';
import { AngularMaterialModule } from '../angular-material.module'
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HistoricalPdfComponent } from './core/pdf-components/historical-pdf/historical-pdf.component'
import { LinechartModule } from './core/chart/linechart/linechart.module';
import { PiechartModule } from './core/chart/piechart/piechart.module';
import { AuthInterceptor } from "./auth.interceptor";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './core/components/spinner/spinner.component';


PlotlyModule.plotlyjs = PlotlyJS;

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HistoricalPdfComponent,
    SpinnerComponent,

  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    LinechartModule,
    PiechartModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    LayoutModule,
    GoogleChartsModule,
    PlotlyModule,
    AngularMaterialModule,
    MatDatepickerModule,
    MatGridListModule,
    MatDialogModule,
    MatFormFieldModule,
    PlotlyModule,
    MatCardModule,
    MatButtonModule,
    FlexModule,
    TranslateModule,
    MatNativeDateModule,

  ],
  bootstrap: [AppComponent],
  providers: [HistoricalPdfComponent, SpinnerComponent,{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }]
})
export class AppModule { }
