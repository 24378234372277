import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PiechartComponent} from './piechart.component';
import {PlotlyModule} from 'angular-plotly.js';
import * as PlotlyJS from 'plotly.js-dist-min';


PlotlyModule.plotlyjs = PlotlyJS;


@NgModule({
  declarations: [PiechartComponent],
  imports: [
    CommonModule,
    PlotlyModule
  ],
  exports:[PiechartComponent]
})
export class PiechartModule { }
