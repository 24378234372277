import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.scss']
})
export class PiechartComponent implements OnInit {

  constructor() { }

  @Input() data: any = [];
  @Input() layout: any = [];
  @Input() chartWidth: any = [];
  @Input() value: any = [];

  cultureInfo = 'ES';



  config = {
    responsive: true,
    scrollZoom: true,
    displayModeBar: false,
    toImageButtonOptions: {
      format: 'png', // one of png, svg, jpeg, webp
      filename: 'ChartResults',
      height: 200,
      width: 600,
      scale: 1
    },
    modeBarButtonsToRemove: ['lasso2d', 'select2d'],
    locale: this.cultureInfo,
    displaylogo: false
  };

  ngOnInit(): void {
  }

}
