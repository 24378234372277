import { Component, ContentChild, ElementRef, Inject, OnInit } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';

import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { extendTraces } from 'plotly.js-dist-min';
import { log } from 'console';
import { BehaviorSubject, Observable } from 'rxjs';

declare var require: any;
const htmlToPdfmake = require("html-to-pdfmake");


pdfMake.vfs = pdfFonts.pdfMake.vfs;



@Component({
  selector: 'app-historical-pdf',
  templateUrl: './historical-pdf.component.html',
  styleUrls: ['./historical-pdf.component.scss']
})
export class HistoricalPdfComponent implements OnInit {

  installation: any = 'San Fidel';
  date: any = '15/09/2002 - 18/09/2022'


  constructor(public translate: TranslateService, @Inject(MAT_DIALOG_DATA) public data: { layout: any }) { }

  ngOnInit(): void {
  }

  pdfImages: any = []


  domToImg(content: any, height: any, parameters: any) {

    const dashboardHeight = content.clientHeight;
    const dashboardWidth = content.clientWidth;
    const options = { background: 'white', width: dashboardWidth, height: dashboardHeight };

    domtoimage.toPng(content, options).then((imgData: any) => {
      const doc = new jsPDF(dashboardWidth > dashboardHeight ? 'l' : 'p', 'mm', [dashboardWidth, dashboardHeight]);
      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      var img = new Image()
      img.src = '../assets/img/Edinor-color.png'
      doc.addImage(img, 'png', 10, 0, 125, 50)

      doc.setFontSize(30);

      let start = parameters.startDateTime;
      let stop = parameters.stopDateTime;
      let granularity = parameters.granularity;



      start = start.split('T');

      start = start[0].split('-').join('')
      stop = stop.split('T');
      stop = stop[0].split('-').join('')
      doc.text(localStorage.getItem('installationName') + '\n' + start + '-' + stop + '-' + granularity, 700, 25);


      doc.addImage(imgData, 'SVG', 0, 50, dashboardWidth, dashboardHeight - 50);
      doc.save(start + '_' + stop + '_' + granularity + '_' + localStorage.getItem('installationName') + '.pdf');
    })

  }



  generatePdf(images: any): void {

    const documentDefinition = {
      content: images
    };

    pdfMake.createPdf(documentDefinition).open();
  }

  colorway = [
    '#33cbcf',
    '#005BB7',
    '#009bd8',
    '#82bf5a',
    '#000000',
    '#1a1f71'
  ];

  chartStyle = "300px";
  chartWidth = "500px";
  chartWidthdonut = "160px";

  cultureInfo = 'ES';


  chartUtils = {
    indicator: {
      layout: {
        margin: { "t": 0, "b": 0, "l": 0, "r": 0 },

      },
      config: {
        responsive: true,
        displayModeBar: false,
        locale: this.cultureInfo,
        displaylogo: false
      },
    },

    donut: {
      dataSavings: [{
        values: [26, 74],
        hoverinfo: 'none',
        hole: .8,
        type: 'pie',
        textinfo: 'none'
      }],
      layoutSavings: {
        font: { size: 10, color: "#33cbcf" },
        showlegend: false,
        colorway: ["#33cbcf", "#C9C9C7"],
        margin: { "t": 0, "b": 0, "l": 0, "r": 0 },
        title: '',
        annotations: [
          {
            font: {
              size: 24
            },
            showarrow: false,
            text: '74€',
            x: 0.5,
            y: 0.5
          }
        ]
      },
      dataIndependence: [{
        values: [75, 25],
        hoverinfo: 'none',
        hole: .8,
        type: 'pie',
        textinfo: 'none'
      }],
      layoutIndependence: {
        font: { size: 10, color: "#33cbcf" },
        showlegend: false,
        colorway: ["#33cbcf", "#C9C9C7"],
        margin: { "t": 0, "b": 0, "l": 0, "r": 0 },

        title: this.translate.instant('historical.battery_operated'),
        annotations: [
          {
            font: {
              size: 24
            },
            showarrow: false,
            text: '25%',
            x: 0.5,
            y: 0.5
          }
        ]
      },
      dataWithoutBattery: [{
        values: [14, 86],
        hoverinfo: 'none',
        hole: .8,
        type: 'pie',
        textinfo: 'none'
      }],
      layoutWithoutBattery: {
        font: { size: 10, color: "#33cbcf" },
        showlegend: false,
        colorway: ["#33cbcf", "#C9C9C7"],
        margin: { "t": 0, "b": 0, "l": 0, "r": 0 },

        title: this.translate.instant('historical.battery_empty'),
        annotations: [
          {
            font: {
              size: 24
            },
            showarrow: false,
            text: '14%',
            x: 0.5,
            y: 0.5
          }
        ]
      },
      config: {
        responsive: true,
        useResizeHandler: true,
        autosize: true,

        toImageButtonOptions: {
          format: 'png', // one of png, svg, jpeg, webp
          filename: 'ChartResults',
          height: 300,
          width: 600,
          scale: 1
        },
        modeBarButtonsToRemove: ['lasso2d', 'select2d'],
        locale: this.cultureInfo,
        displaylogo: false

      },
    },
    pie: {
      data: [{
        values: [14, 86, 47],
        hoverinfo: 'none',
        hole: .8,
        type: 'pie',
        labels: [this.translate.instant('historical.photovoltaic_generation'), this.translate.instant('historical.battery'),
        this.translate.instant('historical.pv_generation')],

      }],
      layout: {
        font: { size: 14, color: "#33cbcf" },
        showlegend: true,
        colorway: ["#33cbcf", "#C9C9C7"],
        margin: { "t": 0, "b": 0, "l": 0, "r": 0 },

        title: this.translate.instant('historical.battery_empty'),
        annotations: [
          {
            font: {
              size: 24
            },
            showarrow: false,
            text: this.translate.instant('historical.consumption'),
            x: 0.5,
            y: 0.5
          }
        ]
      },
      config: {
        responsive: true,
        useResizeHandler: true,
        autosize: true,

        toImageButtonOptions: {
          format: 'png', // one of png, svg, jpeg, webp
          filename: 'ChartResults',
          height: 300,
          width: 600,
          scale: 1
        },
        modeBarButtonsToRemove: ['lasso2d', 'select2d'],
        locale: this.cultureInfo,
        displaylogo: false
      },
    },
    timeToday: {
      data: [
        {
          x: ['2022-02-10 03:00:00', '2022-02-10 06:00:00', '2022-02-10 09:00:00', '2022-02-10 12:00:00', '2022-02-10 15:00:00', '2022-02-10 18:00:00', '2022-02-10 21:00:00', '2022-02-11 00:00:00'],
          y: [400, 450, 600, 1200, 1500, 1000, 500, 300],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.photovoltaic_power_generation'),
        },
        {
          x: ['2022-02-10 03:00:00', '2022-02-10 06:00:00', '2022-02-10 09:00:00', '2022-02-10 12:00:00', '2022-02-10 15:00:00', '2022-02-10 18:00:00', '2022-02-10 21:00:00', '2022-02-11 00:00:00'],
          y: [100, 650, 1300, 400, 200, 1100, 800, 350],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.active_power_consumption'),
        },
        {
          x: ['2022-02-10 03:00:00', '2022-02-10 06:00:00', '2022-02-10 09:00:00', '2022-02-10 12:00:00', '2022-02-10 15:00:00', '2022-02-10 18:00:00', '2022-02-10 21:00:00', '2022-02-11 00:00:00'],
          y: [300, 100, 150, 300, 450, 1200, 1000, 1300],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.active_power_injected'),
        },
        {
          x: ['2022-02-10 03:00:00', '2022-02-10 06:00:00', '2022-02-10 09:00:00', '2022-02-10 12:00:00', '2022-02-10 15:00:00', '2022-02-10 18:00:00', '2022-02-10 21:00:00', '2022-02-11 00:00:00'],
          y: [1000, 1250, 500, 600, 750, 350, 650, 200],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.battery_charge_discharge'),
        },
        {
          x: ['2022-02-10 03:00:00', '2022-02-10 06:00:00', '2022-02-10 09:00:00', '2022-02-10 12:00:00', '2022-02-10 15:00:00', '2022-02-10 18:00:00', '2022-02-10 21:00:00', '2022-02-11 00:00:00'],
          y: [60, 75, 80, 100, 95, 90, 60, 60],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.soc_battery'),
          yaxis: 'y2',
        }
      ],
      layout: {
        margin: { t: 0, r: 0, b: 0, l: 0 },
        showlegend: true,
        barmode: "relative",
        /* height: 500,
        width: 1200, */
        legend: { x: -0.1, y: -0.4, orientation: "h" },
        colorway: this.colorway,

        xaxis: {
          type: 'date',
          automargin: true,
          autorange: true,
          showgrid: true,
          showline: true,
          zeroline: false,
        },
        yaxis: {
          automargin: true,
          autorange: true,
          showgrid: true,
          showline: true,
          zeroline: false,
          ticksuffix: " kW",
        },
        yaxis2: {
          overlaying: 'y',
          side: 'right',
          ticksuffix: '%'
        },
        modeBarButtonsToAdd: []
      },
      config: {
        responsive: true,
        scrollZoom: true,
        displayModeBar: false,
        toImageButtonOptions: {
          format: 'png', // one of png, svg, jpeg, webp
          filename: 'ChartResults',
          height: 300,
          width: 600,
          scale: 1
        },
        modeBarButtonsToRemove: ['lasso2d', 'select2d'],
        locale: this.cultureInfo,
        displaylogo: false
      },
    },

    timeWeek: {
      data: [
        {
          x: ['L - 01/08/2022', 'M - 02/08/2022', 'X - 03/08/2022', 'J - 04/08/2022', 'V - 05/08/2022', 'S - 06/08/2022', 'D - 07/08/2022'],
          y: [500, 350, 700, 1300, 1000, 1500, 800],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.photovoltaic_power_generation'),
        },
        {
          x: ['L - 01/08/2022', 'M - 02/08/2022', 'X - 03/08/2022', 'J - 04/08/2022', 'V - 05/08/2022', 'S - 06/08/2022', 'D - 07/08/2022'],
          y: [200, 750, 1000, 600, 100, 1200, 900],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.active_power_consumption'),
        },
        {
          x: ['L - 01/08/2022', 'M - 02/08/2022', 'X - 03/08/2022', 'J - 04/08/2022', 'V - 05/08/2022', 'S - 06/08/2022', 'D - 07/08/2022'],
          y: [500, 200, 600, 900, 450, 1500, 1200],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.active_power_injected'),
        },
        {
          x: ['L - 01/08/2022', 'M - 02/08/2022', 'X - 03/08/2022', 'J - 04/08/2022', 'V - 05/08/2022', 'S - 06/08/2022', 'D - 07/08/2022'],
          y: [1100, 1650, 900, 300, 950, 550, 450],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.battery_charge_discharge'),
        },
        {
          x: ['L - 01/08/2022', 'M - 02/08/2022', 'X - 03/08/2022', 'J - 04/08/2022', 'V - 05/08/2022', 'S - 06/08/2022', 'D - 07/08/2022'],
          y: [50, 55, 80, 200, 45, 70, 68],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.soc_battery'),
          yaxis: 'y2',
        }
      ],
      layout: {
        margin: { t: 0, r: 0, b: 0, l: 0 },
        showlegend: true,
        barmode: "relative",
        /*  height: 500,
         width: 1200, */
        legend: { x: -0.1, y: -0.4, orientation: "h" },
        colorway: this.colorway,
        xaxis: {
          type: 'string',
          automargin: true,
          autorange: true,
          showgrid: true,
          showline: true,
          zeroline: false
        },
        yaxis: {
          automargin: true,
          autorange: true,
          showgrid: true,
          showline: true,
          zeroline: false,
          ticksuffix: " kW"
        },
        yaxis2: {
          overlaying: 'y',
          side: 'right',
          ticksuffix: '%'
        },
        modeBarButtonsToAdd: []
      },
      config: {
        responsive: true,
        scrollZoom: true,
        displayModeBar: true,
        toImageButtonOptions: {
          format: 'png', // one of png, svg, jpeg, webp
          filename: 'ChartResults',
          height: 300,
          width: 600,
          scale: 1
        },
        modeBarButtonsToRemove: ['lasso2d', 'select2d'],
        locale: this.cultureInfo,
        displaylogo: false
      },
    },

    timeMonth: {
      data: [
        {
          x: ['W - 01 - 07/07/2022', 'W - 08 - 14/07/2022', 'W - 15 - 21/07/2022', 'W - 22 - 28/07/2022', 'W - 28 - 31/07/2022'],
          y: [500, 950, 700, 1300, 1000],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.photovoltaic_power_generation'),
        },
        {
          x: ['W - 01 - 07/07/2022', 'W - 08 - 14/07/2022', 'W - 15 - 21/07/2022', 'W - 22 - 28/07/2022', 'W - 28 - 31/07/2022'],
          y: [700, 650, 500, 1200, 1600],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.active_power_consumption'),
        },
        {
          x: ['W - 01 - 07/07/2022', 'W - 08 - 14/07/2022', 'W - 15 - 21/07/2022', 'W - 22 - 28/07/2022', 'W - 28 - 31/07/2022'],
          y: [300, 200, 500, 300, 600],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.active_power_injected'),
        },
        {
          x: ['W - 01 - 07/07/2022', 'W - 08 - 14/07/2022', 'W - 15 - 21/07/2022', 'W - 22 - 28/07/2022', 'W - 28 - 31/07/2022'],
          y: [500, 200, 700, 300, 400],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.battery_charge_discharge'),
        },
        {
          x: ['W - 01 - 07/07/2022', 'W - 08 - 14/07/2022', 'W - 15 - 21/07/2022', 'W - 22 - 28/07/2022', 'W - 28 - 31/07/2022'],
          y: [50, 60, 70, 30, 10],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.soc_battery'),
          yaxis: 'y2',
        }
      ],
      layout: {
        barmode: "relative",
        margin: { t: 0, r: 0, b: 0, l: 0 },
        showlegend: true,
        /*  height: 500,
         width: 1200, */
        legend: { x: -0.1, y: -0.4, orientation: "h" },
        colorway: this.colorway,
        xaxis: {
          type: 'string',
          automargin: true,
          autorange: true,
          showgrid: true,
          showline: true,
          zeroline: false
        },
        yaxis: {
          automargin: true,
          autorange: true,
          showgrid: true,
          showline: true,
          zeroline: false,
          ticksuffix: " kW"
        },
        yaxis2: {
          overlaying: 'y',
          side: 'right',
          ticksuffix: '%'
        },
        modeBarButtonsToAdd: []
      },
      config: {
        responsive: true,
        scrollZoom: true,
        displayModeBar: true,
        toImageButtonOptions: {
          format: 'png', // one of png, svg, jpeg, webp
          filename: 'ChartResults',
          height: 300,
          width: 600,
          scale: 1
        },
        modeBarButtonsToRemove: ['lasso2d', 'select2d'],
        locale: this.cultureInfo,
        displaylogo: false
      },
    },
    timeYear: {
      data: [
        {
          x: [this.translate.instant('historical.january'), this.translate.instant('historical.february'),
          this.translate.instant('historical.march'), this.translate.instant('historical.april'),
          this.translate.instant('historical.may'), this.translate.instant('historical.june'),
          this.translate.instant('historical.july'), this.translate.instant('historical.august'),
          this.translate.instant('historical.september'), this.translate.instant('historical.october'),
          this.translate.instant('historical.november'), this.translate.instant('historical.december')],
          y: [400, 450, 600, 1200, 1500, 1000, 500, 300, 600, 800, 700, 900],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.exported_energy') + 'Potencia fotovoltaica generada (kW)',
        },
        {
          x: [this.translate.instant('historical.january'), this.translate.instant('historical.february'),
          this.translate.instant('historical.march'), this.translate.instant('historical.april'),
          this.translate.instant('historical.may'), this.translate.instant('historical.june'),
          this.translate.instant('historical.july'), this.translate.instant('historical.august'),
          this.translate.instant('historical.september'), this.translate.instant('historical.october'),
          this.translate.instant('historical.november'), this.translate.instant('historical.december')],
          y: [100, 650, 1300, 400, 200, 1100, 800, 350, 400, 600, 800, 600],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.exported_energy') + 'Potencia activa consumida (kW)',
        },
        {
          x: [this.translate.instant('historical.january'), this.translate.instant('historical.february'),
          this.translate.instant('historical.march'), this.translate.instant('historical.april'),
          this.translate.instant('historical.may'), this.translate.instant('historical.june'),
          this.translate.instant('historical.july'), this.translate.instant('historical.august'),
          this.translate.instant('historical.september'), this.translate.instant('historical.october'),
          this.translate.instant('historical.november'), this.translate.instant('historical.december')],
          y: [300, 100, 150, 300, 450, 1200, 1000, 1300, 1000, 1500, 1200, 900],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.exported_energy') + 'Potencia activa inyectada a red (kW)',
        },
        {
          x: [this.translate.instant('historical.january'), this.translate.instant('historical.february'),
          this.translate.instant('historical.march'), this.translate.instant('historical.april'),
          this.translate.instant('historical.may'), this.translate.instant('historical.june'),
          this.translate.instant('historical.july'), this.translate.instant('historical.august'),
          this.translate.instant('historical.september'), this.translate.instant('historical.october'),
          this.translate.instant('historical.november'), this.translate.instant('historical.december')],
          y: [1000, 1250, 500, 600, 750, 350, 650, 200, 400, 600, 200, 800],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.exported_energy') + 'Potencia de carga/descarga de batería (kW)',
        },
        {
          x: [this.translate.instant('historical.january'), this.translate.instant('historical.february'),
          this.translate.instant('historical.march'), this.translate.instant('historical.april'),
          this.translate.instant('historical.may'), this.translate.instant('historical.june'),
          this.translate.instant('historical.july'), this.translate.instant('historical.august'),
          this.translate.instant('historical.september'), this.translate.instant('historical.october'),
          this.translate.instant('historical.november'), this.translate.instant('historical.december')],
          y: [60, 75, 80, 100, 95, 90, 60, 60, 70, 50, 60, 90],
          type: "scatter",
          mode: "lines",
          name: this.translate.instant('historical.soc_battery'),
          yaxis: 'y2',
        }
      ],
      layout: {
        barmode: "relative",
        margin: { t: 0, r: 0, b: 0, l: 0 },
        showlegend: true,
        /*  height: 500,
         width: 1200, */
        legend: { x: -0.1, y: -0.4, orientation: "h" },
        colorway: this.colorway,
        xaxis: {
          type: 'string',
          automargin: true,
          autorange: true,
          showgrid: true,
          showline: true,
          zeroline: false
        },
        yaxis: {
          automargin: true,
          autorange: true,
          showgrid: true,
          showline: true,
          zeroline: false,
          ticksuffix: " kW"
        },
        yaxis2: {
          overlaying: 'y',
          side: 'right',
          ticksuffix: '%'
        },
        modeBarButtonsToAdd: []
      },
      config: {
        responsive: true,
        scrollZoom: true,
        displayModeBar: true,
        toImageButtonOptions: {
          format: 'png', // one of png, svg, jpeg, webp
          filename: 'ChartResults',
          height: 300,
          width: 600,
          scale: 1
        },
        modeBarButtonsToRemove: ['lasso2d', 'select2d'],
        locale: this.cultureInfo,
        displaylogo: false
      },
    },


    emptyLayout: '<div class="d-flex h-100 align-items-center justify-content-center blockquote">' + this.translate.instant('historical.no_data') + '</div>'
  };


}
