import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/components/auth/guard/auth.guard';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./core/components/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'installations',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./core/components/installation/installation.module').then((m) => m.InstallationModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./layout/layout.module').then((m) => m.LayoutModule),
  },
  { path: '**', redirectTo: 'auth' },
];

export const rotuingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  useHash: true
};

@NgModule({
  imports: [RouterModule.forRoot(routes, rotuingConfiguration)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
