import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHandler, HttpHeaders, HttpParams, HttpRequest } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { Observable, of, throwError, tap, BehaviorSubject } from 'rxjs';
import { TokenService } from "./token.service";
import { webSocket } from 'rxjs/webSocket';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})



export class ApiService {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private refreshTokenTimeout: any;

  _xGeneration: number[] = [];
  _yGeneration: number[] = [];
  _xConsumo: number[] = [];
  _yConsumo: number[] = [];
  _xInjection: number[] = [];
  _yInjection: number[] = [];
  _xCharge: number[] = [];
  _yCharge: number[] = [];
  _xSoc: number[] = [];
  _ySoc: number[] = [];
  _iter = 0;
  data$: any;

  generation: any;
  consumo: any;
  charge: any;

  prueba: any = [];

  private static log(message: string): any {
  }

  constructor(public http: HttpClient, public tokenService: TokenService, public router: Router) {

   }

  handleError(error: HttpErrorResponse): any {
    var err: any;
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      if (error.status >= 500) {
        err = 'No se ha podido recuperar la información'
      } else if (error.status < 500 && error.status != 401) {
        err = 'No se ha podido hacer la llamada'
      } else if (error.status == 401){
        err = 'Ha ocurrido un error, intentalo de nuevo.';
      } else {
        err = 'Algo ha salido mal, por favor, vuelva a intentarlo.'
      }

    }
    return err;
  }

  getHeaders(): HttpHeaders | undefined {
    const token = localStorage.getItem('access_token');

    let headers = new HttpHeaders();
    headers = headers
      .set('content-type', 'application/json')
      .set('Authorization', 'Bearer ' + token);

    return headers;
  }

  getHeadersBlob(): HttpHeaders | undefined {
    const token = localStorage.getItem('access_token');

    let headers = new HttpHeaders();
    headers = headers
      .set('content-type', 'application/xml')
      .set('Authorization', 'Bearer ' + token);

    return headers;
  }



  getHeadersAuth(): HttpHeaders {
    let headers = new HttpHeaders();

    headers = headers
      .set('content-type', 'application/x-www-form-urlencoded')
      .set('accept', 'application/json')
      .set('Authorization', 'Basic ' + btoa(environment.client_id + ':' + environment.secret));

    return headers;
  }

/*   refreshToken(refreshData: any): Observable<any> {

    const body = new HttpParams()
      .set('refresh_token', refreshData.refreshToken)
      .set('grant_type', 'refresh_token');
    return this.http.post<any>(environment.baseUrlAuth + 'oauth/token', body, { headers: this.getHeadersAuth() })
      .pipe(
        tap(res => {
          var now = Date.now();

          var expires = res.expires_in * 1000;

          var total = now + expires;

          this.tokenService.saveToken(res.access_token);
          this.tokenService.saveRefreshToken(res.refresh_token);
          this.tokenService.saveExpires(total);
        })

      );
  }

 */

  connect(url: string) {

    const subject = webSocket(url);
    this.data$ = subject.pipe(
      map((msg: any) => {
        return msg;
      }),
      catchError(error => {
        this.connect(url);
        throw error;
      }),
    );

    return this.data$;
  }
}
