import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from './layout.component';
import {AsideMenuComponent} from './components/aside-menu/aside-menu.component';
import {HeaderComponent} from './components/header/header.component';
import {ContentComponent} from './components/content/content.component';
import {Routing} from '../pages/routing';
import {FooterComponent} from './components/footer/footer.component';
import {TranslateModule} from "@ngx-translate/core";
import {AngularMaterialModule} from "../../angular-material.module";

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: Routing,
  },
];

@NgModule({
  declarations: [
    LayoutComponent,
    AsideMenuComponent,
    FooterComponent,
    HeaderComponent,
    ContentComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    AngularMaterialModule
  ],
  exports: [RouterModule, HeaderComponent],
  providers: [HeaderComponent]
})
export class LayoutModule {}
