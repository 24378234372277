import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError, Observable, of, tap } from "rxjs";
import { TokenService } from "../token.service";
import { ApiService } from "../api.service";
import { environment } from "../../../../environments/environment";
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root',
})
export class AuthService extends ApiService {

  refreshTokenTimeOutMethod: any;

  constructor(http: HttpClient, tokenService: TokenService, router: Router) {
    super(http, tokenService, router);
  }

  login(loginData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    let data = "username=" + loginData.username + "&password=" + encodeURIComponent(loginData.password) + "&grant_type=password&" +
      "client_secret=" + environment.secret + "&client_id=" + environment.client_id;

    return this.http.post<any>(environment.baseUrlAuth + 'oauth/token', data, { headers: this.getHeadersAuth() })
      .pipe(
        tap(res => {
          this.tokenService.saveToken(res.access_token);
          this.tokenService.saveRefreshToken(res.refresh_token);
          this.tokenService.saveExpires(res.expires_in);
          this.startSetTimeOutRefresh();
        }),

      )
  }


  logout() {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    localStorage.removeItem('auth');
    localStorage.removeItem('token');
    this.router.navigate(['login']);
  }

  refreshToken(refreshToken: any) {
    console.log('do refresh ');
    this.getRefreshToken(this.tokenService.getRefreshToken()).subscribe({
      next: (res) => {
        this.tokenService.saveToken(res.access_token);
        this.tokenService.saveRefreshToken(res.refresh_token);
        this.tokenService.saveExpires(res.expires_in);
        this.startSetTimeOutRefresh();
      },
      error: (err) => {
        this.logout();
        return of(false);
      }
    })
  }

  getRefreshToken(refresh: any): Observable<any> {

    let headers1 = new HttpHeaders();
     headers1 = headers1
    .set('content-type', 'application/x-www-form-urlencoded')
    .set('accept', 'application/json')
    .set('Authorization', 'Basic ' + btoa(environment.client_id + ':' + environment.secret));


    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', this.tokenService.getRefreshToken());

    return this.http.post<any>(environment.baseUrlAuth + 'oauth/token', body, { headers: headers1 }).pipe(
      tap((response: any) => {
        this.tokenService.saveToken(response.access_token);
        this.tokenService.saveRefreshToken(response.refresh_token);
        this.tokenService.saveExpires(response.expires_in);
        this.startSetTimeOutRefresh();
      }),
      catchError((error) => {
       this.logout();
        return of(false);
      })
    );
  }

  headersAuthToken() {
    let headers = new HttpHeaders();
     headers = headers
    .set('content-type', 'application/x-www-form-urlencoded')
    .set('accept', 'application/json')
    .set('Authorization', 'Basic ' + btoa(environment.client_id + ':' + environment.secret));
    return headers;
  }

  secured(): Observable<any> {
    return this.http.get<any>(environment.baseUrlAuth + 'secret')
      .pipe();

  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user') || 'null');
    const token = localStorage.getItem('access_token');
    return user !== null && token !== null;
  }

  startSetTimeOutRefresh() {
    console.log('start');
    let exp = Number(this.tokenService.getExpires()) * 1000;

    this.refreshTokenTimeOutMethod = setTimeout(() => {
      this.getRefreshToken('res').subscribe( res => console.log('refresh')); }, exp);
  }

}
