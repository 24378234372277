import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PlotlyModule} from 'angular-plotly.js';
import * as PlotlyJS from 'plotly.js-dist-min';
import {LinechartComponent} from './linechart.component';


PlotlyModule.plotlyjs = PlotlyJS;


@NgModule({
  declarations: [LinechartComponent],
  imports: [
    CommonModule,
    PlotlyModule
  ],
  exports:[LinechartComponent]
})
export class LinechartModule { }
